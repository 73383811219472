import React from 'react'
import Helmet from 'react-helmet'
import { styled } from '@mui/material'
import OrderDetails from '@components/order/status/order-details'
import Layout from '../../components/layout'

const OrderDetailsWrapper = () => (
  <Layout>
    <Helmet title="Order Status Details - Rooms To Go" />
    <OrderDetails />
  </Layout>
)

export default OrderDetailsWrapper
